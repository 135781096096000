/* * {
    border: 1px solid red;
} */

.splash-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.splash-video-conatiner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 60vh;
    width: 100%;
    position: relative;
    padding-bottom: .08rem;
    margin-bottom: 3rem;
    /* overflow-y: auto; */
    text-align: center;
}

.splash-page-video {
    width: 200vh;
    height: 70vh;
    object-fit: cover;
}

.splash-video-title {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 20px;
}

.splash-video-text {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.5;
    width: 37rem;
    margin-top: 2rem;
}

.deparment-buttons-container {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.5;
    width: 37rem;
    margin-top: 4.6rem;
}

.mens-button {
    font-family: "SF Pro Text",-apple-system,Helvetica,Arial,"Segoe UI",Roboto,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 20px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 25px;
}

.mens-button:hover {
    cursor: pointer;
    border: none;
    color: white;
    background-color: #0000ff;
}

.womens-button {
    font-family: "SF Pro Text",-apple-system,Helvetica,Arial,"Segoe UI",Roboto,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 20px;
    background-color: transparent;
    color: white;
    border: 1px solid white;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 25px;
}

.womens-button:hover {
    cursor: pointer;
    border: none;
    color: white;
    background-color: #0000ff;
}

.splash-card-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    height: fit-content;
    margin-bottom: 2em;
}

.splash-carousels-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.staff-picks {
    padding-top: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.deals {
    padding-top: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.deals-text {
    display: flex;
    flex-direction: column;
    font-size: 21px;
    padding-left: 3em;
}

.splash-carousel-container {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    margin-bottom: 2em;
}

.back-arrow,
.next-arrow {
    padding-bottom: 6em;
}

.carousel-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 15px;
    position: relative;
    z-index: 0;
}

.splash-bottom-container {
    display: flex;
    justify-content: center;
    padding-top: 70px;
    width: 100%;
    position: relative;
}

.splash-bottom-img {
    object-fit: cover;
    background-color: #f5f5f5;
    overflow: hidden;
    width: 100%;
    position: relative;
    height: 370px;
}

.splash-bottom-text,
.splash-bottom-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
    text-align: center;
}

.splash-bottom-text {
    color: white;
    font-size: 39px;
    font-weight: 300;
    width: 50%;
    margin-bottom: 4rem;
}

.splash-bottom-button {
    background-color: #0000ff;
    color: white;
    font-size: 17px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 58px;
    padding-right: 58px;
    margin-top: 8.2rem;
    border: none;
    cursor: pointer;
}

.splash-bottom-button:hover {
    background-color: #2e2efe;
}

.splash-footer-container {
    display: flex;
    justify-content: start;
    /* align-items: center; */
    background-color: white;
    padding: 10px;
    margin-top: 7px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #555;
}

.footer-img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
}

.footer-name {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: bold;
}

.github-img {
    margin-bottom: 10px;
    max-width: 16px;
}

.footer-img-container div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.github-text {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    margin-left: 3px;
    font-weight: bold;
}

.github-text:hover {
    cursor: pointer;
    text-decoration: underline;
}
