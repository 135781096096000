/* TODO Add site wide styles */
* {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

@keyframes slide {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

body {
    font-family: "SF Pro Text",-apple-system,Helvetica,Arial,"Segoe UI",Roboto,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}


select {
  appearance: none;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

select:hover {
  border-color: #aaa;
}

select:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

select option {
  background-color: #fff;
  color: #333;
}

.like-icon {
  justify-self: flex-end;
}

.feedback-button {
  display: flex;
  flex-direction: row;
  width: 175px;
  height: 35px;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  font-size: 17px;
  cursor: pointer;
  transition: background-color 0.2s ease-in;
  font-family: "Video",monospace;
}

.feedback-button:hover {
  color: black;
  background-color: white;
}

.feedback-button:active {
  background-color: rgba(205, 205, 205, 0.5);
  transform: translateY(0.5px) translateX(0.5px);
}

.icon-button {
  border: none;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  width: fit-content;
}

.icon-button:active {
  transform: translateY(0.5px) translateX(0.5px);
}

.item-card {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  
}

.item-card:hover {
  transform: scale(1.04);
  /* box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.35); */
}

i {
  height: fit-content;
  width: fit-content;
}

input {
  font-size: 16px;
  font-weight: 200;
}

textarea {
  font-size: 16px;
  font-weight: 200;
}

.error-messages {
  display: flex;
  flex-direction: column;
  /* justify-content: start; */
  /* align-items: center; */
  color: #ff0000;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ff0000;
  background-color: #ffe6e6;
}

.image-error {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: start; */
  align-items: center;
  color: #ff0000;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ff0000;
  background-color: #ffe6e6;
}

div.active-tab {
  border: 2px solid red;
}
