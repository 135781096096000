.filter-labels-container {
    display: flex;
    flex-direction: column;
}

#filters-label {
    margin-bottom: 10px;
    font-size: 18px;
}
.cat-filter-btn {
    font-size: 16px;
    font-weight: 200;
    line-height: 1.2;
}

.cat-filter-btn:hover {
    text-decoration: underline;
}

.all-filter-btn:hover {
    text-decoration: underline;
}

.reset-btn {
    margin-top: 10px;
    font-size: 16px;
    text-decoration: underline;
}