.title {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.purchase-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    gap: 5%;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 1em;
}

.purchase-img-container {
    max-width: 300px;
    max-height: 400px;
    min-width: 300px;
    min-height: 400px;
    object-fit: cover;
}

.purchase-image {
    max-width: 300px;
    max-height: 400px;
    min-width: 300px;
    min-height: 400px;
    object-fit: cover;
}

.purchase-info {
    line-height: 1.5;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    padding-top: 1em;
    justify-content: space-between;
}

.purchase-date {
    display: flex;
    flex-direction: column;
}

.sale-price {
    display: flex;
    flex-direction: column;
}

.payment-type {
    display: flex;
    flex-direction: column;
}

.purchase-info-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10%;
    width: 100%;
}

.item-info {
    display: flex;
    line-height: 1.5;
    flex-direction: column;
    border-top: 1px solid lightgrey;
    padding-top: 2em;   
}

.buttons-container {
    display: flex;
    padding-top: 1em;
}