.favorites-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    padding: 20px;
}

.item-info-container {
    border-top: 1px solid lightgrey;
    margin-top: 5px;
    padding-top: 5px;
}

.fav-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: flex-start;
    width: 100%;
}

.favorites-container img {
    max-width: 100%;
}
