.single-item-page-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8em;
}

.arrows-carousel-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* item carousel */
.single-item-carousel-container{
    display: flex;
    flex-direction: row;
    padding-top: 42px;
    padding-right: 5em;
    padding-left: 5em;
}

.single-item-image {
    height: 680px;
    width: 530px;
    object-fit: cover;
}

/* item info */
.item-info-buttons-container{
    width: 25%;
    margin-top: 4.6rem;
    display: flex;
    flex-direction: column;
    /* border: 1px solid black; */
}

.item-name-favs-container {
    display: flex;
    justify-content: space-between;
}

#item-name {
    font-weight: bold;
    font-size: 18px;
}

.size {
    padding-top: 15px;
    font-weight: 200;
    font-size: 16px;
}

.color, .condition {
    font-weight: 200;
    padding-top: 10px;
    font-size: 16px;
}

.price {
    padding-top: 15px;
    font-family: "Video",monospace;
    font-weight: bold;
    font-size: 25px;
}

.shipping {
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 200;
}

.purchase-button {
    background-color: black;
    font-size: 17px;
    color: white;
    border: none;
    padding-top: 13px;
    padding-bottom: 13px;
}

.purchase-button:hover {
    cursor: pointer;
    background-color: #333333;
} 

.item-desc-title {
    border-top: 1px solid #d0d0d0;
    margin-top: 15px;
    padding-top: 15px;
    font-weight: bold;
}

.item-desc {
    padding-top: 10px;
    font-weight: 200;
}

.purchase-button-container {
    padding-bottom: 2em;
}

/* .edit-delete-btn-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
} */