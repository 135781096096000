
/* logged in drop down menu */

.profile-button {
    background-color: rgb(240, 240, 240);
    border: none;
    border-radius: 25px;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.fa-user {
    color:rgb(200, 200, 200);
    font-size: 17px;
    /* padding: 30px; */
}

/* .fa-solid {
    color:rgb(187, 187, 187);
    padding: 30px;
} */

.dropdown-menu-container {
    border: 1px solid rgb(220, 220, 220);

    background-color: white;
    text-align: left;
    white-space: nowrap;
    position: absolute;
    top: 3%;
    right: 25%;
    width: fit-content;
    padding-top: 6px;
    padding-bottom: 6px;
}

.drop-down-border {
    border: 1px solid rgb(235, 235, 235);
}

.dropdown-username,
.dropdown-favorites,
.dropdown-logout {
    padding-right: 20px;
    padding-left: 13px;
    display: flex;
    justify-content: start;
    align-items: center;
    border: none;
    text-decoration: none;
    background-color: transparent;
    margin-top: 4px;
    margin-bottom: 4px;
    color: black;
}

.dropdown-username,
.dropdown-favorites {
    padding-bottom: 5px;
}

.dropdown-logout {
    padding-top: 5px;
    cursor: pointer;
}

.dropdown-username:hover,
.dropdown-favorites:hover,
.dropdown-logout:hover {
    color: blue;
}



