
.login-signup-modal-container {
    height: 600px;
    width: 420px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.modal-title {
    font-size: 34px;
    padding-top: 30px;
    margin-left: 25px;
    text-align: center;
    display: flex;
    align-items: center;
}

.login-signup-desc {
    margin-left: 25px;
    margin-right: 100px;
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 27px;
    line-height: 20px;
    color: #454545;
    font-size: 15px;
    margin-bottom: 20px;
}

.login-signup-form {
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    flex-direction: column;
}

.login-signup-label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    font-weight: bold;
}

.login-signup-label-text {
    margin-bottom: 5px;
}

.login-signup-input {
    font-size: 16px;
    margin-bottom: 15px;
    padding: 20px;
    padding-bottom: 15px;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 5px;
}

.login-signup-button {
    background-color: black;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    margin-top: 25px;
    touch-action: manipulation;
}

.login-signup-button:hover {
    background-color: rgb(26, 26, 26);
}

.alternate-logins {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-weight: 200;
    padding: 0 10px;

}

.alternate-logins::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: rgb(221, 221, 221);
    margin-right: 16px;
}

.alternate-logins::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: rgb(221, 221, 221);
    margin-left: 16px;
}

.modal-demo-button {
    margin-top: 15px;
    background: transparent;
    border-width: 1px;
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    margin-top: 20px;
    touch-action: manipulation;
    border: 1px solid rgb(220, 220, 220);
}

.modal-demo-button:hover {
    background-color:rgb(220, 220, 220);
    border: none;
}
