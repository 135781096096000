.listings-tab-container {
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.listings-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    gap: 5%;
    margin-left: 20px;
}

.listings-container .listing-liked {
    justify-self: flex-end;
}

.listings-container img {
    width: 100%;
}

.item-card-listings {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.item-card-listings img {
    padding-bottom: 0.5em;
}

.item-info-container {
    border-top: 1px solid lightgrey;
    margin-top: 5px;
    padding-top: 5px;
}