.signup-modal-container {
    max-height: 55vh;
    width: 500px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    padding-bottom: 20px;
    overflow-y: scroll;
}

.signup-desc {
    margin-left: 25px;
    margin-right: 100px;
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 27px;
    line-height: 20px;
    color: #454545;
    font-size: 15px;
    padding-bottom: 35px;
}

.signup-input {
    font-size: 16px;
    padding: 20px;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 5px;
}

