.profile-dropdown {
  position: absolute;
  z-index: 1;
}

.hidden {
  display: none;
}

.navbar {
  border-bottom: 1px solid rgb(230, 230, 230);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  padding: 10px;
}

.home-link-container {
  margin-right: 50px;
  text-align: center;
}

.home-link {
  text-decoration: none;
  color: black;
  font-size: 25px;
  font-weight: 400;
}

.search-input {
  height: 40px;
  width: 440px;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
}

.search-button {
  background: transparent;
  color: rgb(0, 0, 0);
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  border: 1px solid rgb(220, 220, 220);
}

.search-button:hover {
  background-color: rgb(220, 220, 220);
  border: none;
}

.right-side-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 100px;
  align-items: center;
}

.sell-button {
  background: transparent;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 16px;
  border: 1px solid rgb(0, 0, 0);
  width: 75px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 27px;
}

.sell-button:hover {
  color: blue;
}

.shop-link {
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: black;
  margin-right: 20px;

}

.shop-link:hover {
  color: blue;
}


.favorites-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 25px;
  margin-right: 20px;

}

/* logged out navbar */

.nav-login-button {
  background: transparent;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #ccc;
  width: 75px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-right: 20px;

}

.nav-login-button:hover {
  color: blue;
  border: 1px solid black;
}

.nav-signup-button {
  background-color: black;
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.nav-signup-button:hover {
  background-color: #333333;
}