.purchase-form-container {
    height: 750px;
    width: 620px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    padding-bottom: 20px;
    overflow-y: scroll;
}

.purchase-title {
    border-bottom: 1px solid #dbd6d6;
    text-align: center;
    display: flex;
    padding-top: 40px;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 27px;
    font-size: 23px;
}

.purchase-form {
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
}

.purchase-label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    font-weight: bold;
}

.purchase-input {
    font-size: 16px;
    margin-bottom: 15px;
    padding: 20px;
    padding-bottom: 15px;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 5px;
}

.purchase-form-button {
    background-color: black;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 25px;
    font-family: "Video", monospace;
    display: block;
    touch-action: manipulation;
    margin-bottom: 25px;
}

.purchase-form-button:hover {
    background-color: rgb(26, 26, 26);
}