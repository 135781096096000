.review-card-container {
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-bottom: 1px solid lightgrey;
    justify-content: space-between;
    margin-left: 120px;
    margin-right: 120px;
    /* border: 1px solid black; */
}

.review-card-container .review-body {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    /* border: 1px solid black; */
}

.review-card-container .review-img-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    height: 200px;
    /* border: 1px solid black; */
}

.review-body span {
    margin: 8px;
}

.review-body .review-date {
    font-weight: bold;
}

.review-body .review-description {
    font-weight: bold;
    text-decoration: underline;
}

.review-card-stars-container {
    color: green;
}