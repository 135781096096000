.edit-feedback-container {
    height: 545px;
    width: 425px;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    /* padding-bottom: 20px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.feedback-title {
    border-bottom: 1px solid #dbd6d6;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 27px;
    font-size: 23px;
}

.feedback-form {
    margin-left: 40px;
    margin-right: 40px;
    display: flex;
    font-weight: 200;
    flex-direction: column;
}


.feedback-label {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 18px;
    font-weight: bold;

}

.feedback-text {
    font-weight: 200;
    font-size: 16px;
    resize: none;
    margin-bottom: 15px;
    padding: 30px;
    width: 300px;
    padding-bottom: 45px;
    border: 1px solid rgb(220, 220, 220);
}

.feedback-input {
    width: 300px;
    font-size: 16px;
    font-weight: 200;
    margin-bottom: 15px;
    padding: 20px;
    padding-bottom: 15px;
    border: 1px solid rgb(220, 220, 220);
}

.feedback-form-button {
    background-color: black;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 25px;
    font-family: "Video", monospace;
    display: block;
    touch-action: manipulation;
}

.feedback-form-button:hover {
    background-color: rgb(26, 26, 26);
}

.delete-feedback-button {
    background-color: black;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    height: 48px;
    /* padding-left: 17px; */
    /* padding-right: 17px; */
    margin-top: 25px;
    font-family: "Video", monospace;
    display: block;
    touch-action: manipulation;
    margin-top: 15px;
}

.delete-feedback-button:hover {
    background-color: rgb(26, 26, 26);
}

.or {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-weight: 200;
    padding: 0 10px;

}

.or::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: rgb(221, 221, 221);
    margin-right: 16px;
}

.or::after {
    content: "";
    /* display: block; */
    width: 100%;
    height: 1px;
    background-color: rgb(221, 221, 221);
    margin-left: 16px;
}