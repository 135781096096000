/* * {
    border: 1px solid red;
} */

/* profile header */
.user-dash-container {
    margin-top: 25px;
    margin-left: 200px;
    margin-right: 200px;
}

.profile-header {
    display: flex;
    flex-direction: row;
    padding-bottom: 40px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgb(227, 227, 227);
    justify-content: space-between;
    width: 100%;
}

.dashboard-banner {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
}

.prof-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid rgb(218, 218, 218);
}

.joined-in-container {
    display: flex;
    flex-direction: column;
}

.stars-container,
.transaction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 5em;
    /* margin-right: 20px; */
    border-right: 1px solid rgb(218, 218, 218);
}

.prof-followers-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid rgb(218, 218, 218);
}

.fa-circle-user {
    font-size: 43px;
    margin-right: 15px;
}

.profile-username {
    font-weight: bold;
    padding-bottom: 7px;
    font-size: 20px;
}

.profile-stars,
.profile-transactions-count {
    font-weight: bold;
    padding-bottom: 6px;

}

.total-reviews,
.transactions {
    font-weight: 100;
}

.edit-button-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: end;
}

.profile-edit {
    background-color: white;
    padding: 15px;
    /* border: none; */
    font-size: 16px;
    border: 1px solid rgb(195, 194, 194);
}

.profile-edit:hover {
    cursor: pointer;
    background-color: rgb(238, 238, 238);
}

/* tabs */

.tab-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgb(225, 225, 225);
    /* padding-bottom: 40px; */
    padding-bottom: 30px;
}

.purchases-tab,
.favorites-tab,
.available-listings-tab,
.feedback-tab {
    font-weight: 200;
    font-size: 16px;
    margin-right: 50px;
}

.purchases-tab:hover,
.favorites-tab:hover,
.available-listings-tab:hover,
.feedback-tab:hover {
    cursor: pointer;
    /* font-weight: bold; */
    font-size: 16px;
}


.selected {
    font-weight: bold;
}