.prof-card-container {
    margin-top: 13px;
    padding-top: 1em;
    line-height: 1.4;
    display: flex;
    flex-direction: row;
    border-top: 1px solid lightgrey;
}

.prof-card-user {
    font-weight: 200;
    font-weight: bold;
    font-size: 14px;
}

.prof-card-rating {
    padding-top: 2px;
    font-size: 14px;
}

.prof-card-transaction, .prof-card-items {
    padding-top: 2px;
    font-weight: 200;
    font-size: 14px;
    /* line-height: 1.5; */
}