.main-listings-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 100%;
    justify-content: center;
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 20px;
}

.filters {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 4em;
    max-width: 400px;
    padding: 1em;
    max-height: 225px;
    background-color: rgb(255, 255, 255);
}

.items-display-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    justify-content: flex-start;
    gap: 7px;
}

.home-item-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    height: fit-content;
    margin-bottom: 2em;
}